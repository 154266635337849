/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DynamicFieldOptionDto } from './DynamicFieldOptionDto';

export type DynamicFieldWithOptionsDto = {
    /**
     * Id do campo dinâmico.
     */
    id: number;
    /**
     * Nome do campo dinâmico.
     */
    name: string;
    /**
     * Rótulo do campo dinâmico.
     */
    label: string;
    /**
     * Tipo do campo dinâmico.
     */
    type: DynamicFieldWithOptionsDto.type;
    /**
     * Id da organização.
     */
    organizationId: number;
    /**
     * Data de criação do campo dinâmico.
     */
    createdAt: string;
    /**
     * Data de atualização do campo dinâmico.
     */
    updatedAt: string;
    /**
     * Opções do campo dinâmico.
     */
    fieldOptions?: Array<DynamicFieldOptionDto>;
};

export namespace DynamicFieldWithOptionsDto {

    /**
     * Tipo do campo dinâmico.
     */
    export enum type {
        TEXT = 'Text',
        NUMERIC = 'Numeric',
        LIST = 'List',
    }


}

