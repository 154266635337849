import { FC, lazy, Suspense } from "react";
import { useAuth } from "../contexts/Auth";
import { ReactComponent as Logo } from "../assets/images/alke-logo-colored.svg";

const PrivateRoutes = lazy(() => import("./private"));
const PublicRoutes = lazy(() => import("./public"));

const Spinner = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      background: "FAFAFA",
    }}
    className="fade-in-out"
  >
    <Logo />
  </div>
);

const Routes: FC = () => {
  const { signedIn, loading } = useAuth();

  if (loading) return <Spinner />;

  return (
    <Suspense fallback={<Spinner />}>
      {signedIn ? <PrivateRoutes /> : <PublicRoutes />}
    </Suspense>
  );
};

export default Routes;
