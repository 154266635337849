import { PRIVATE_ROUTES } from "utils/constants";

export enum PermissionModules {
  Basic = "Basic",
  Organizations = "Organizations",
  Users = "Users",
  UsersInOtherOrganizations = "UsersInOtherOrganizations",
  UsersWithAdminAccess = "UsersWithAdminAccess",
  DynamicFieldsAndEntities = "DynamicFieldsAndEntities",
  AccessProfiles = "AccessProfiles",
  AccessProfilesInOtherOrganizations = "AccessProfilesInOtherOrganizations",
  AccessProfilesWithAdminAccess = "AccessProfilesWithAdminAccess",
  Companies = "Companies",
  Budgets = "Budgets",
  Projects = "Projects",
  Charges = "Charges",
}

export const FeaturesPermissions = {
  [PermissionModules.Basic]: 0,
  [PermissionModules.Organizations]: {
    View: 1,
    Manage: 2,
  },
  [PermissionModules.Users]: {
    View: 3,
    Manage: 4,
  },
  [PermissionModules.UsersInOtherOrganizations]: {
    View: 5,
    Manage: 6,
  },
  [PermissionModules.UsersWithAdminAccess]: {
    View: 7, // Não temos uma permissão para View, mas entendemos que se o usuário tem permissão para Manage, ele também tem permissão para View
    Manage: 7,
  },
  [PermissionModules.DynamicFieldsAndEntities]: {
    View: 8,
    Manage: 9,
  },
  [PermissionModules.AccessProfiles]: {
    View: 10,
    Manage: 11,
  },
  [PermissionModules.AccessProfilesInOtherOrganizations]: {
    View: 12,
    Manage: 13,
  },
  [PermissionModules.AccessProfilesWithAdminAccess]: {
    View: 14, // Não temos uma permissão para View, mas entendemos que se o usuário tem permissão para Manage, ele também tem permissão para View
    Manage: 14,
  },
  [PermissionModules.Companies]: {
    View: 15,
    Manage: 16,
  },
  [PermissionModules.Budgets]: {
    CreateBudget: 19,
    ElaborateBudget: 20,
    ArchiveBudget: 21,
    ProcessBudget: 22,
    ViewBudget: 23,
  },
  [PermissionModules.Projects]: {
    AssignProjectManager: 18,
    ViewProject: 24,
    UpdateProject: 33,
  },
  [PermissionModules.Charges]: {
    ViewCharge: 25,
    ManageBillingRules: 26,
    AntecipateCharges: 27,
    StartBillingAccount: 28,
    InvoiceCharges: 29,
    RenegotiateCharges: 30,
    CreateAccounts: 31,
    ArchiveAccounts: 32,
  },
} as any;

export enum Actions {
  View = "View",
  Manage = "Manage",
  CreateBudget = "CreateBudget",
  ElaborateBudget = "ElaborateBudget",
  ArchiveBudget = "ArchiveBudget",
  ProcessBudget = "ProcessBudget",
  ViewBudget = "ViewBudget",
  AssignProjectManager = "AssignProjectManager",
  ViewProject = "ViewProject",
  ViewCharge = "ViewCharge",
  ManageBillingRules = "ManageBillingRules",
  AntecipateCharges = "AntecipateCharges",
  StartBillingAccount = "StartBillingAccount",
  InvoiceCharges = "InvoiceCharges",
  RenegotiateCharges = "RenegotiateCharges",
  CreateAccounts = "CreateAccounts",
  ArchiveAccounts = "ArchiveAccounts",
  UpdateProject = "UpdateProject",
}

export const COMPANIES_MODULES = [PermissionModules.Companies];

export const ORGANIZATIONS_MODULES = [PermissionModules.Organizations];

export const USER_MODULES = [
  PermissionModules.Users,
  PermissionModules.UsersInOtherOrganizations,
  PermissionModules.UsersWithAdminAccess,
];

export const ACCESS_PROFILES_MODULES = [
  PermissionModules.AccessProfiles,
  PermissionModules.AccessProfilesInOtherOrganizations,
  PermissionModules.AccessProfilesWithAdminAccess,
];

export const DYNAMIC_FIELDS_MODULES = [
  PermissionModules.DynamicFieldsAndEntities,
];

export const PERMISSION_TO_ROUTE_MAP = {
  [PRIVATE_ROUTES.backoffice.companies]: {
    modules: COMPANIES_MODULES,
    actions: [Actions.View, Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.createCompany]: {
    modules: COMPANIES_MODULES,
    actions: [Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.companyDetails()]: {
    modules: COMPANIES_MODULES,
    actions: [Actions.View, Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.editCompany()]: {
    modules: COMPANIES_MODULES,
    actions: [Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.organizations]: {
    modules: ORGANIZATIONS_MODULES,
    actions: [Actions.View, Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.users.table]: {
    modules: USER_MODULES,
    actions: [Actions.View, Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.users.create]: {
    modules: USER_MODULES,
    actions: [Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.users.details()]: {
    modules: USER_MODULES,
    actions: [Actions.View, Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.users.edit()]: {
    modules: USER_MODULES,
    actions: [Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.accessProfiles]: {
    modules: ACCESS_PROFILES_MODULES,
    actions: [Actions.View, Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.accessProfileDetails()]: {
    modules: ACCESS_PROFILES_MODULES,
    actions: [Actions.View, Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.createAccessProfile]: {
    modules: ACCESS_PROFILES_MODULES,
    actions: [Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.editAccessProfile()]: {
    modules: ACCESS_PROFILES_MODULES,
    actions: [Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.dynamicFields]: {
    modules: DYNAMIC_FIELDS_MODULES,
    actions: [Actions.View, Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.createDynamicField]: {
    modules: DYNAMIC_FIELDS_MODULES,
    actions: [Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.editDynamicFields()]: {
    modules: DYNAMIC_FIELDS_MODULES,
    actions: [Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.dynamicFieldDetails()]: {
    modules: DYNAMIC_FIELDS_MODULES,
    actions: [Actions.View, Actions.Manage],
  },
  [PRIVATE_ROUTES.backoffice.fieldsAndEntities]: {
    modules: DYNAMIC_FIELDS_MODULES,
    actions: [Actions.View, Actions.Manage],
  },
};

export const ADMIN_PERMISSIONS = [
  FeaturesPermissions[PermissionModules.Organizations].View,
  FeaturesPermissions[PermissionModules.Organizations].Manage,
  FeaturesPermissions[PermissionModules.UsersInOtherOrganizations].View,
  FeaturesPermissions[PermissionModules.UsersInOtherOrganizations].Manage,
  FeaturesPermissions[PermissionModules.UsersWithAdminAccess].Manage,
  FeaturesPermissions[PermissionModules.AccessProfilesInOtherOrganizations]
    .View,
  FeaturesPermissions[PermissionModules.AccessProfilesInOtherOrganizations]
    .Manage,
  FeaturesPermissions[PermissionModules.AccessProfilesWithAdminAccess].Manage,
];

export const BUDGETS_PERMISSIONS = [
  FeaturesPermissions[PermissionModules.Budgets].ArchiveBudget,
  FeaturesPermissions[PermissionModules.Budgets].CreateBudget,
  FeaturesPermissions[PermissionModules.Budgets].ElaborateBudget,
  FeaturesPermissions[PermissionModules.Budgets].ProcessBudget,
  FeaturesPermissions[PermissionModules.Budgets].ViewBudget,
];

export const PROJECTS_PERMISSIONS = [
  FeaturesPermissions[PermissionModules.Projects].ViewProject,
  FeaturesPermissions[PermissionModules.Projects].AssignProjectManager,
  FeaturesPermissions[PermissionModules.Projects].UpdateProject,
];

export const CHARGES_PERMISSIONS = [
  FeaturesPermissions[PermissionModules.Charges].ViewCharge,
  FeaturesPermissions[PermissionModules.Charges].ManageBillingRules,
  FeaturesPermissions[PermissionModules.Charges].AntecipateCharges,
  FeaturesPermissions[PermissionModules.Charges].StartBillingAccount,
  FeaturesPermissions[PermissionModules.Charges].InvoiceCharges,
  FeaturesPermissions[PermissionModules.Charges].RenegotiateCharges,
  FeaturesPermissions[PermissionModules.Charges].CreateAccounts,
  FeaturesPermissions[PermissionModules.Charges].ArchiveAccounts,
];
