import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "contexts/Auth";
import { SnackbarContainer } from "shared/components/Snackbar";
import Routes from "./routes";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import brLocale from "date-fns/locale/pt-BR";
import "./global.css";
import { outerTheme } from "MuiTheme";
import { PermissionsProvider } from "contexts/Permissions";
import TopBarProvider from "./contexts/TopBar";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "components/ErrorFallback/ErrorFallback";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  return (
    <StyledEngineProvider>
      <ThemeProvider theme={outerTheme}>
        <Router>
          <SnackbarContainer>
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}
            >
              <AuthProvider>
                <PermissionsProvider>
                  <TopBarProvider>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={brLocale}
                    >
                      <DndProvider backend={HTML5Backend}>
                        <Box>
                          <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <Routes />
                          </ErrorBoundary>
                        </Box>
                      </DndProvider>
                    </LocalizationProvider>
                  </TopBarProvider>
                </PermissionsProvider>
              </AuthProvider>
            </GoogleOAuthProvider>
          </SnackbarContainer>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
