/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateOrganizationDto = {
    status?: CreateOrganizationDto.status;
    name: string;
};

export namespace CreateOrganizationDto {

    export enum status {
        ACTIVE = 'Active',
        INACTIVE = 'Inactive',
    }


}

