export const PUBLIC_ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  CHANGE_PASSWORD: "/change-password",
};

export const PRIVATE_ROUTES = {
  backoffice: {
    basename: "/backoffice/*",
    companies: "/backoffice/companies",
    createCompany: "/backoffice/companies/create",
    companyDetails: (id = ":id") => `/backoffice/companies/${id}`,
    editCompany: (id = ":id") => `/backoffice/companies/edit/${id}`,
    editAccessProfile: (id = ":id") => `/backoffice/access-profiles/edit/${id}`,
    organizations: "/backoffice/organizations",
    users: {
      table: "/backoffice/users",
      create: "/backoffice/users/create",
      details: (id = ":id") => `/backoffice/users/${id}`,
      edit: (id = ":id") => `/backoffice/users/edit/${id}`,
    },
    accessProfiles: "/backoffice/access-profiles",
    createAccessProfile: `/backoffice/access-profiles/create`,
    accessProfileDetails: (id = ":id") => `/backoffice/access-profiles/${id}`,
    fieldsAndEntities: "/backoffice/fields-and-entities",
    dynamicFields: "/backoffice/dynamic-field",
    createDynamicField: "/backoffice/dynamic-field/create",
    editDynamicFields: (id = ":id") => `/backoffice/dynamic-field/edit/${id}`,
    dynamicFieldDetails: (id = ":id") => `/backoffice/dynamic-field/${id}`,
  },
  HOME: "/",
  PRIVATE_POLICY: "/private-policy",
};

export enum TopBarMenu {
  BACKOFFICE = "Backoffice",
}

export const STATUS_CODE = {
  CREATED: 201,
  SUCCESS_OK: 200,
  CLIENT_ERROR_BAD_REQUEST: 400,
  CLIENT_ERROR_UNAUTHORIZED: 401,
  CLIENT_ERROR_NOT_FOUND: 404,
  CLIENT_PRECONDITION_FAILED: 412,
  SERVER_ERROR_INTERNAL: 500,
};

export const PAGINATION_CONFIG = {
  ITEMS_PER_PAGE: 10,
};

export const DELETE_MODAL = {
  TITLE: "Exclusão de",
  CONTENT: "Tem certeza que deseja apagar",
};
