/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrganizationDto } from '../models/CreateOrganizationDto';
import type { ListOrganizationResponseDTO } from '../models/ListOrganizationResponseDTO';
import type { Organization } from '../models/Organization';
import type { UpdateOrganizationDto } from '../models/UpdateOrganizationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrganizationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Organizations
     * @returns ListOrganizationResponseDTO List of Organizations matching filter criteria
     * @throws ApiError
     */
    public getOrganizations({
        page,
        itemsPerPage = 10,
        orderDirection = 'DESC',
        name,
        status,
        orderBy = 'name',
    }: {
        /**
         * The number of desired page. Starts with 1.
         */
        page: number,
        /**
         * The maximum number of rows to contain in current page.
         */
        itemsPerPage?: number,
        /**
         * Ordering direction
         */
        orderDirection?: 'ASC' | 'DESC',
        name?: string,
        status?: 'Active' | 'Inactive',
        orderBy?: 'name' | 'status',
    }): CancelablePromise<ListOrganizationResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/organization',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'orderDirection': orderDirection,
                'name': name,
                'status': status,
                'orderBy': orderBy,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Create a new organization
     * @returns Organization Organization Created with Success
     * @throws ApiError
     */
    public createOrg({
        requestBody,
    }: {
        requestBody: CreateOrganizationDto,
    }): CancelablePromise<Organization> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/organization',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Update the given Organization
     * @returns Organization Organization updated with Success.
     * @throws ApiError
     */
    public updateOrganization({
        organizationId,
        requestBody,
    }: {
        /**
         * The id of the organization to be actioned on
         */
        organizationId: number,
        /**
         * The information to be updated on the given Organization.
         */
        requestBody: UpdateOrganizationDto,
    }): CancelablePromise<Organization> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/organization/{organizationId}',
            path: {
                'organizationId': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Delete the given Organization
     * @returns Organization Organization deleted with Success.
     * @throws ApiError
     */
    public deleteOrganization({
        organizationId,
    }: {
        organizationId: string,
    }): CancelablePromise<Organization> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/organization/{organizationId}',
            path: {
                'organizationId': organizationId,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Mark the given Organization as Active
     * @returns Organization Organization updated with Success.
     * @throws ApiError
     */
    public activateOrganization({
        organizationId,
    }: {
        organizationId: string,
    }): CancelablePromise<Organization> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/organization/{organizationId}/activate',
            path: {
                'organizationId': organizationId,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Mark the given Organization as Inactive
     * @returns Organization Organization updated with Success
     * @throws ApiError
     */
    public inactivateOrganization({
        organizationId,
    }: {
        organizationId: string,
    }): CancelablePromise<Organization> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/organization/{organizationId}/inactivate',
            path: {
                'organizationId': organizationId,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

}
