import axios from "axios";
import { generateApiAuthInterceptor } from "../api-auth-interceptor";

import { OpenAPIConfig, SwaggerClient } from "../__generated__";

const baseURL = process.env.REACT_APP_BACKEND_BASE_URL ?? "backoffice-api.alke.rarolabs.com.br";
console.log(process.env.REACT_APP_BACKEND_BASE_URL);

axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;
generateApiAuthInterceptor(axios);

const OpenAPI: OpenAPIConfig = {
  BASE: baseURL,
  VERSION: "3.0",
  WITH_CREDENTIALS: true,
  CREDENTIALS: "include",
  TOKEN: "",
  USERNAME: undefined,
  PASSWORD: undefined,
  HEADERS: undefined,
  ENCODE_PATH: undefined,
};

export const swaggerClient = new SwaggerClient(OpenAPI);
