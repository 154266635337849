/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DetailedUserResponse = {
    id: number;
    name: string;
    email: string;
    organizationId: number;
    organizationName: string;
    avatar?: string;
    status: DetailedUserResponse.status;
    accessProfilesIds: Array<number>;
};

export namespace DetailedUserResponse {

    export enum status {
        PENDING = 'PENDING',
        ACTIVE = 'ACTIVE',
        INACTIVE = 'INACTIVE',
    }


}

