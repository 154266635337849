import { createContext, useState } from "react";
import { CustomHeader, TopBarContextValues } from "./types";

export const TopBarContext = createContext<TopBarContextValues>({
  customHeader: {
    title: "",
  },
  setCustomHeader: (_) => null,
  openSideBar: true,
  setOpenSideBar: () => null,
  formModified: false,
  setFormModified: () => null,
  blockBackToPage: false,
  setBlockBackToPage: () => null,
  openSaveModal: false,
  setOpenSaveModal: () => null,
});

const TopBarProvider: React.FC = ({ children }) => {
  const [customHeader, setCustomHeader] = useState<CustomHeader>({
    title: "",
  });
  const [openSideBar, setOpenSideBar] = useState(true);
  const [formModified, setFormModified] = useState(false);
  const [blockBackToPage, setBlockBackToPage] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);

  return (
    <TopBarContext.Provider
      value={{
        customHeader,
        setCustomHeader,
        openSideBar,
        setOpenSideBar,
        formModified,
        setFormModified,
        blockBackToPage,
        setBlockBackToPage,
        openSaveModal,
        setOpenSaveModal,
      }}
    >
      {children}
    </TopBarContext.Provider>
  );
};
export default TopBarProvider;
