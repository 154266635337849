import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export function CheckSuccessIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.33333 0C1.49238 0 0 1.49238 0 3.33333V16.6667C0 18.5076 1.49238 20 3.33333 20H16.6667C18.5076 20 20 18.5076 20 16.6667V3.33333C20 1.49238 18.5076 0 16.6667 0H3.33333ZM15.2301 7.45234C15.664 7.01843 15.664 6.31491 15.2301 5.88099C14.7962 5.44708 14.0927 5.44708 13.6588 5.88099L7.77778 11.762L5.78567 9.76988C5.35176 9.33597 4.64824 9.33597 4.21433 9.76988C3.78041 10.2038 3.78041 10.9073 4.21433 11.3412L6.20643 13.3333C7.07426 14.2012 8.4813 14.2012 9.34913 13.3333L15.2301 7.45234Z"
        fill="#4CAF50"
      />
    </svg>
  );
}
