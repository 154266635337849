/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UploadResponse } from '../models/UploadResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UploadService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns UploadResponse URL da imagem carregada.
     * @throws ApiError
     */
    public uploadFile({
        formData,
    }: {
        formData: {
            file?: Blob;
        },
    }): CancelablePromise<UploadResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/uploads',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request.`,
            },
        });
    }

}
