/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AccessProfilesService } from './services/AccessProfilesService';
import { AddressTypeService } from './services/AddressTypeService';
import { AuthService } from './services/AuthService';
import { CompaniesService } from './services/CompaniesService';
import { ContactTypeService } from './services/ContactTypeService';
import { DefaultService } from './services/DefaultService';
import { DynamicFieldsService } from './services/DynamicFieldsService';
import { FeaturesService } from './services/FeaturesService';
import { OrganizationsService } from './services/OrganizationsService';
import { UploadService } from './services/UploadService';
import { UsersService } from './services/UsersService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class SwaggerClient {

    public readonly accessProfiles: AccessProfilesService;
    public readonly addressType: AddressTypeService;
    public readonly auth: AuthService;
    public readonly companies: CompaniesService;
    public readonly contactType: ContactTypeService;
    public readonly default: DefaultService;
    public readonly dynamicFields: DynamicFieldsService;
    public readonly features: FeaturesService;
    public readonly organizations: OrganizationsService;
    public readonly upload: UploadService;
    public readonly users: UsersService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.0.1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.accessProfiles = new AccessProfilesService(this.request);
        this.addressType = new AddressTypeService(this.request);
        this.auth = new AuthService(this.request);
        this.companies = new CompaniesService(this.request);
        this.contactType = new ContactTypeService(this.request);
        this.default = new DefaultService(this.request);
        this.dynamicFields = new DynamicFieldsService(this.request);
        this.features = new FeaturesService(this.request);
        this.organizations = new OrganizationsService(this.request);
        this.upload = new UploadService(this.request);
        this.users = new UsersService(this.request);
    }
}

