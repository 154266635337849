/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BodyContactTypeDto } from '../models/BodyContactTypeDto';
import type { ContactTypeResponse } from '../models/ContactTypeResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ContactTypeService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Contact Types
     * @returns any
     * @throws ApiError
     */
    public getContactType({
        page,
        itemsPerPage = 10,
        orderDirection = 'DESC',
        name,
        organizationId,
    }: {
        /**
         * The number of desired page. Starts with 1.
         */
        page: number,
        /**
         * The maximum number of rows to contain in current page.
         */
        itemsPerPage?: number,
        /**
         * Ordering direction
         */
        orderDirection?: 'ASC' | 'DESC',
        name?: string,
        /**
         * Organization Id
         */
        organizationId?: string,
    }): CancelablePromise<{
        data: Array<ContactTypeResponse>;
        page: number;
        total: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/contact-types',
            headers: {
                'organization-id': organizationId,
            },
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'orderDirection': orderDirection,
                'name': name,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Create a new Contact Type
     * @returns ContactTypeResponse Contact Type created with Success
     * @throws ApiError
     */
    public createContactType({
        requestBody,
        organizationId,
    }: {
        requestBody: BodyContactTypeDto,
        /**
         * Organization Id
         */
        organizationId?: string,
    }): CancelablePromise<ContactTypeResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/contact-types',
            headers: {
                'organization-id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

}
