import { FC, useState, createContext, useCallback } from "react";

import { SnackbarContextType } from "./types";
import { Snackbar, SnackbarProps } from "../Snackbar";

const SnackbarContext = createContext<SnackbarContextType>(
  {} as SnackbarContextType
);

const SnackbarContainer: FC = ({ children }) => {
  const [state, setState] = useState<Partial<SnackbarProps>>({});

  const showMessage = useCallback((props: Omit<SnackbarProps, "open">) => {
    setState({ ...props, open: true });
  }, []);

  const onClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <>
      <SnackbarContext.Provider value={{ showMessage }}>
        {children}
      </SnackbarContext.Provider>
      <Snackbar
        title={state?.title}
        message={state?.message || ""}
        open={state?.open}
        onClose={onClose}
        type={state?.type || "error"}
        position={{
          horizontal: state?.position?.horizontal ?? "right",
          vertical: state?.position?.vertical ?? "bottom",
        }}
      />
    </>
  );
};

export { SnackbarContainer };
export default SnackbarContext;
