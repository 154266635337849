import React, { useContext } from "react";
import { AlertTypeOptions } from "shared/components/Alert/types";
import { SnackbarProps } from "../Snackbar";
import SnackbarContext from "./SnackbarContainer";
import { SnackbarContextType } from "./types";

export default function useSnackbar() {
  const { showMessage } = useContext<SnackbarContextType>(SnackbarContext);

  return React.useMemo(() => {
    const createMessage =
      (type: AlertTypeOptions) =>
      (
        title?: string,
        message: string = "",
        customParameters?: Partial<
          Omit<SnackbarProps, "message" | "title" | "type">
        >
      ) =>
        showMessage({ title, message, ...customParameters, type });
    return {
      showInfo: createMessage("info"),
      showWarning: createMessage("warning"),
      showError: createMessage("error"),
      showSuccess: createMessage("success"),
    };
  }, [showMessage]);
}
