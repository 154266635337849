/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateDynamicFieldsOptionDto } from './CreateDynamicFieldsOptionDto';

export type CreateDynamicFieldDto = {
    /**
     * Nome do campo dinâmico.
     */
    name: string;
    /**
     * Rótulo do campo dinâmico.
     */
    label: string;
    /**
     * Tipo do campo dinâmico.
     */
    type: CreateDynamicFieldDto.type;
    /**
     * Opções do campo dinâmico. Só se aplica se o tipo do campor for List.
     */
    fieldOptions?: Array<CreateDynamicFieldsOptionDto> | null;
};

export namespace CreateDynamicFieldDto {

    /**
     * Tipo do campo dinâmico.
     */
    export enum type {
        TEXT = 'Text',
        NUMERIC = 'Numeric',
        LIST = 'List',
    }


}

