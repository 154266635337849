import { useSnackbar } from "shared/components/Snackbar";
import { useEffect } from "react";

export const ErrorFallback: React.FC = () => {
  const snackbar = useSnackbar();
  useEffect(() => {
    snackbar.showError("Oops!", "Algo deu errado, tente novamente mais tarde");
    if (process.env.NODE_ENV !== "development") {
      setTimeout(() => window.location.replace("/"), 150);
    }
  }, [snackbar]);

  return <></>;
};
