import { AlertProps } from "../Alert/types";
import Alert from "../Alert";
import { Snackbar as MuiSnackbar } from "@mui/material";

export type SnackbarProps = Omit<AlertProps, "mini"> & {
  open?: boolean;
  position?: {
    horizontal?: "right" | "left" | "center";
    vertical?: "bottom" | "top";
  };
} & (
    | {
        autoHideDuration?: never;
        onClose?: () => void;
      }
    | {
        autoHideDuration: number;
        onClose: () => void;
      }
  );

export const Snackbar: React.FC<SnackbarProps> = ({
  message,
  title,
  type,
  autoHideDuration = 10000,
  position,
  onClose,
  open,
}) => (
  <MuiSnackbar
    anchorOrigin={{
      horizontal: position?.horizontal || "right",
      vertical: position?.vertical || "bottom",
    }}
    autoHideDuration={autoHideDuration}
    onClose={onClose}
    open={open}
  >
    <div>
      <Alert title={title} message={message} type={type} />
    </div>
  </MuiSnackbar>
);
