import { useCallback, useContext, useEffect, useState } from "react";
import { TopBarContext } from "../topbar";
import { CustomHeader } from "../types";
import usePrevious from "../../../utils/hooks/usePrevious";
import isEqual from "lodash/isEqual";
import { useLocation } from "react-router";
import { BACKOFFICE_COMPONENTS } from "pages/Backoffice/constants";

const useTopBar = (header?: CustomHeader) => {
  const {
    setCustomHeader,
    customHeader,
    openSideBar,
    setOpenSideBar,
    formModified,
    setFormModified,
    blockBackToPage,
    setBlockBackToPage,
    openSaveModal,
    setOpenSaveModal,
  } = useContext(TopBarContext);

  const previousHeader = usePrevious(customHeader);
  const { pathname } = useLocation();

  const [returnRoute, setReturnRoute] = useState<string>();

  const getReturnRoute = useCallback(() => {
    [...BACKOFFICE_COMPONENTS]
      .sort((a, b) => a.order - b.order)
      .forEach((item) => {
        if (item.route === pathname) {
          setReturnRoute(pathname);
        }
      });
  }, [pathname]);

  useEffect(() => {
    getReturnRoute();
    setCustomHeader({ title: "", route: "" });
    setOpenSideBar(false);
  }, [pathname, setCustomHeader, getReturnRoute, setOpenSideBar]);

  useEffect(() => {
    if (!isEqual(previousHeader, header) && header) {
      setCustomHeader(header);
    }
  }, [header, previousHeader, setCustomHeader]);

  return {
    customHeader,
    openSideBar,
    setOpenSideBar,
    returnRoute,
    formModified,
    setFormModified,
    blockBackToPage,
    setBlockBackToPage,
    openSaveModal,
    setOpenSaveModal,
  };
};
export default useTopBar;
