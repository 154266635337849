import { Alert, styled } from "@mui/material";
import { AlertProps } from "./types";

export const StyledAlert = styled(Alert)<
  Pick<AlertProps, "title" | "mini" | "type">
>(({ theme, mini, type, title }) => ({
  width: mini ? "fit-content" : "400px",
  background: theme.palette[type].background || undefined,
  boxShadow: mini ? "" : theme.customShadows[1],
  padding: mini ? "0px 8px" : "6px 16px",

  " > .MuiAlert-icon": {
    marginRight: "14px",
    color: theme.palette[type].main || undefined,
    fontSize: "20px",
    paddingBlock: !!title ? "6px" : mini ? "4px" : "6px",
  },

  "> .MuiAlert-message": {
    paddingBlock: !!title || mini ? "6px" : "8px",
    background: theme.palette[type].content || undefined,
    "-webkit-text-fill-color": "transparent",
    "-webkit-background-clip": "text",
  },
}));
