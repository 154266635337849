/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeatureDto } from '../models/FeatureDto';
import type { FeatureRouteDto } from '../models/FeatureRouteDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FeaturesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Features routes for Access Profiles
     * @returns any
     * @throws ApiError
     */
    public findAllRoutes({
        page,
        itemsPerPage = 10,
        orderDirection = 'DESC',
        uri,
        isPublic,
        verb,
        orderBy = 'id',
    }: {
        /**
         * The number of desired page. Starts with 1.
         */
        page: number,
        /**
         * The maximum number of rows to contain in current page.
         */
        itemsPerPage?: number,
        /**
         * Ordering direction
         */
        orderDirection?: 'ASC' | 'DESC',
        /**
         * Search routes by URI
         */
        uri?: string,
        /**
         * Search routes by visibility
         */
        isPublic?: boolean,
        /**
         * Search routes by HTTP verb
         */
        verb?: string,
        /**
         * Property to order by
         */
        orderBy?: 'id' | 'verb' | 'uri' | 'isPublic',
    }): CancelablePromise<{
        data: Array<FeatureRouteDto>;
        page: number;
        total: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/features-routes',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'orderDirection': orderDirection,
                'uri': uri,
                'isPublic': isPublic,
                'verb': verb,
                'orderBy': orderBy,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * List Features for Access Profiles
     * @returns any
     * @throws ApiError
     */
    public findAllFeatures({
        page,
        itemsPerPage = 10,
        orderDirection = 'DESC',
        active,
        isAdmin,
        orderBy = 'id',
    }: {
        /**
         * The number of desired page. Starts with 1.
         */
        page: number,
        /**
         * The maximum number of rows to contain in current page.
         */
        itemsPerPage?: number,
        /**
         * Ordering direction
         */
        orderDirection?: 'ASC' | 'DESC',
        /**
         * Sort by active features
         */
        active?: boolean,
        /**
         * Search by features only available for admin
         */
        isAdmin?: boolean,
        /**
         * Property to order by
         */
        orderBy?: 'id' | 'description' | 'active' | 'isAdmin',
    }): CancelablePromise<{
        data: Array<FeatureDto>;
        page: number;
        total: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/features',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'orderDirection': orderDirection,
                'active': active,
                'isAdmin': isAdmin,
                'orderBy': orderBy,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

}
