import { createTheme } from "@mui/material/styles";
import { COLORS } from "shared/constants";
// TODO: Mover cores para um arquivo de constantes
export const outerTheme = createTheme({
  palette: {
    background: {
      default: COLORS.almostWhite,
      paper: COLORS.white,
    },
    primary: {
      main: COLORS.purple,
      light: COLORS.lightPurple,
      dark: COLORS.darkPurple,
      contrastText: COLORS.white,
      containedHoverBackground: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${COLORS.purple}`,
      containedPressedBackground: `linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)), ${COLORS.purple}`,
      outlinedHoverBackground: `${COLORS.purple}0A`,
      outlinedRestingBackground: COLORS.darkPurple,
      outlineSelectedBackground: "rgba(223,20,74,0.04)",
    },
    secondary: {
      main: COLORS.almostBlack,
      light: "#6F74DD",
      dark: "#00227B",
      contrastText: COLORS.white,
      outlinedRestingHover: "#3949AB80",
    },
    error: {
      main: COLORS.error,
      contrastText: COLORS.white,
      containedHoverBackground: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),${COLORS.error}`,
      containedPressedBackground: `linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)),${COLORS.error}`,
      outlinedRestingBorder: `${COLORS.error}80`,
      outlinedPressedBorder: `${COLORS.error}14`,
      content: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), ${COLORS.error}`,
      background: `linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), ${COLORS.error}`,
    },
    success: {
      main: "#4CAF50",
      light: "#4CAF5099",
      content:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #4CAF50",
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), #4CAF50",
    },
    warning: {
      main: "#ED6C02",
      light: "#FFB547",
      content:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #ED6C02",
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ED6C02",
    },
    info: {
      main: "#6F74DD",
      content: "#00227B",
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3949AB",
    },
    text: {
      primary: COLORS.almostBlack,
      secondary: COLORS.lightGray,
      disabled: "#00000061",
    },
    skillLevel: {
      1: "rgba(40, 188, 178, 0.3)",
      3: "rgba(159, 111, 221, 0.35)",
      5: "rgba(19, 84, 237, 0.3)",
    },
    action: {
      active: "#0000008A",
      hover: "#00000008",
      disabled: "#00000042",
      disabledBackground: COLORS.lightPurple,
      disabledFloatingButton:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #FFFFFF",
    },
    other: {
      lightWhite: COLORS.lightWhite,
      neutral4: COLORS.neutral4,
      divider: COLORS.border,
      border: COLORS.border,
      silver: COLORS.silver,
      almostWhite: COLORS.almostWhite,
      backgroundBlur: "#00000026",
      emptyStateBackground: "#00000008",
      emptyStateBorder: "#00000014",
      tooltipBackground: "#595959",
      tooltipIcon: "#FFFFFFB2",
      badgeBackground: "#EEEEEE",
      headerItemHover: "#8E59FF3D",
      headerItemSelected: COLORS.purple,
      gradient: COLORS.gradient,
      fieldsHoverBackground:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)), #FFFFFF",
    },
  },
  customShadows: [
    "none",
    "0px 4px 4px rgba(0, 0, 0, 0.15)",
    "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    "0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);",
  ],
  typography: {
    fontFamily: '"Inter", normal',
    allVariants: {
      fontFamily: '"Inter", normal',
    },
    h1: {
      fontWeight: 400,
      fontSize: "6rem",
      lineHeight: "143.2%",
    },
    h2: {
      fontWeight: 500,
      fontSize: "3.75rem",
      lineHeight: "143.2%",
    },
    h3: {
      fontWeight: 500,
      fontSize: "1.875rem",
      lineHeight: "143.2%",
    },
    h4: {
      fontWeight: 500,
      fontSize: "0.8125rem",
      lineHeight: "143.2%",
    },

    subtitle1: {
      fontWeight: 300,
      fontSize: "0.8125rem",
      lineHeight: "160%",
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "100%",
    },
    body2: {
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: "24px",
    },
    caption1: {
      fontFamily: '"Inter", normal',
      fontWeight: 500,
      fontSize: "1.125rem",
      lineHeight: "32px",
    },
    caption: {
      fontWeight: 600,
      fontSize: "0.8125rem",
      lineHeight: "16px",
      fontFamily: '"Inter", normal',
    },
    smallButton: {
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: "24px",
    },
    mediumButton: {
      fontWeight: 700,
      fontSize: "0.9375rem",
      lineHeight: "24px",
    },
    largeButton: {
      fontWeight: 700,
      fontSize: "13px",
      lineHeight: "24px",
    },
    base1: {
      fontFamily: '"Inter", normal',
      fontWeight: 600,
      fontSize: "0.9375rem",
      lineHeight: "24px",
      display: "block",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: "16px",
          marginBlock: "0px 2px",
          lineHeight: "24px",
          letter: "0.15px",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "rgba(0,0,0,0.6)",
          "&$error": {
            color: COLORS.purple,
          },
          fontWeight: 400,
          fontSize: "0.75rem",
          lineHeight: "20px",
          letterSpacing: "0.025rem",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiTextField-root": { my: 2 },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.6)",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: "0.5rem",
          "& .MuiInputBase-root": {
            backgroundColor: "white",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: "0px",
          backgroundColor: "white",
          fontSize: "1rem",
          lineHeight: "24px",
          fontWeight: 400,
          letterSpacing: "-0.03125rem",
        },
        formControl: {
          backgroundColor: "white",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontWeight: 500,
          fontSize: "0.625rem",
          lineHeight: "14px",
          letterSpacing: "0",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: "0.8125rem",
          lineHeight: "18px",
          letterSpacing: "0.01rem",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#F4F4F4",
          textOverflow: "ellipsis",
          maxWidth: "30vw",
          overflow: "hidden",
          paddingBlock: "0px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: "3.5rem",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: "8px",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: COLORS.neutral4,
          fontWeight: 600,
          fontSize: "0.875rem",
          lineHeight: "24px",
          "&:hover": {
            backgroundColor: COLORS.silver,
            color: COLORS.purple,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          zIndex: 1000,
        },
        option: {
          paddingInline: "16px",
          color: COLORS.neutral4,
          fontWeight: 600,
          fontSize: "0.875rem",
          lineHeight: "24px",
          "&:hover": {
            backgroundColor: COLORS.silver,
            color: COLORS.purple,
          },
          '&[aria-selected="true"]': {
            backgroundColor: COLORS.silver,
            color: COLORS.purple,
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        anchorOriginBottomCenter: {
          "@media (min-width: 600px)": {
            bottom: "40px",
          },
        },
        anchorOriginBottomLeft: {
          "@media (min-width: 600px)": {
            bottom: "40px",
            left: "40px",
          },
        },
        anchorOriginBottomRight: {
          "@media (min-width: 600px)": {
            bottom: "40px",
            right: "40px",
          },
        },
        anchorOriginTopCenter: {
          "@media (min-width: 600px)": {
            top: "40px",
          },
        },
        anchorOriginTopLeft: {
          "@media (min-width: 600px)": {
            top: "40px",
            left: "40px",
          },
        },
        anchorOriginTopRight: {
          "@media (min-width: 600px)": {
            top: "40px",
            right: "40px",
          },
        },
      },
    },
  },
});
