/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Organization = {
    id: number;
    name: string;
    status: Organization.status;
    createdAt: string;
    updatedAt: string;
};

export namespace Organization {

    export enum status {
        ACTIVE = 'Active',
        INACTIVE = 'Inactive',
    }


}

