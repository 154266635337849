/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DynamicFieldWithoutOptionsDto = {
    /**
     * Id do campo dinâmico.
     */
    id: number;
    /**
     * Nome do campo dinâmico.
     */
    name: string;
    /**
     * Rótulo do campo dinâmico.
     */
    label: string;
    /**
     * Tipo do campo dinâmico.
     */
    type: DynamicFieldWithoutOptionsDto.type;
    /**
     * Id da organização.
     */
    organizationId: number;
    /**
     * Data de criação do campo dinâmico.
     */
    createdAt: string;
    /**
     * Data de atualização do campo dinâmico.
     */
    updatedAt: string;
};

export namespace DynamicFieldWithoutOptionsDto {

    /**
     * Tipo do campo dinâmico.
     */
    export enum type {
        TEXT = 'Text',
        NUMERIC = 'Numeric',
        LIST = 'List',
    }


}

