/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthUser } from '../models/AuthUser';
import type { ChangePasswordDto } from '../models/ChangePasswordDto';
import type { ForgotPasswordDto } from '../models/ForgotPasswordDto';
import type { LiveBlocksAuthDto } from '../models/LiveBlocksAuthDto';
import type { LiveBlocksResponseDto } from '../models/LiveBlocksResponseDto';
import type { LoginResponseDto } from '../models/LoginResponseDto';
import type { OAuthUser } from '../models/OAuthUser';
import type { TokenUserInfo } from '../models/TokenUserInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Change user password
     * @returns any Password changed with success
     * @throws ApiError
     */
    public changePassword({
requestBody,
}: {
requestBody: ChangePasswordDto,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/change-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Send email to reset user password
     * @returns any Reset password email sended with success
     * @throws ApiError
     */
    public forgotPassword({
requestBody,
}: {
requestBody: ForgotPasswordDto,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/forgot-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found: The organization Id provided was not found in our database`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Get user info
     * @returns TokenUserInfo User info returned with success
     * @throws ApiError
     */
    public getProfile(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/auth/profile',
            errors: {
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * log in the application
     * @returns LoginResponseDto User logged in with success
     * @throws ApiError
     */
    public login({
requestBody,
}: {
requestBody: AuthUser,
}): CancelablePromise<LoginResponseDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * log in the application with Azure
     * @returns LoginResponseDto User logged in with success
     * @throws ApiError
     */
    public loginAzure({
requestBody,
}: {
requestBody: OAuthUser,
}): CancelablePromise<LoginResponseDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/login-azure',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * log in the application with Google
     * @returns LoginResponseDto User logged in with success
     * @throws ApiError
     */
    public loginGoogle({
requestBody,
}: {
requestBody: OAuthUser,
}): CancelablePromise<LoginResponseDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/login-google',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * logou from application
     * @returns void
     * @throws ApiError
     */
    public logout(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/logout',
            errors: {
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Authenticates user in Liveblocks room
     * @returns LiveBlocksResponseDto User logged in with success
     * @throws ApiError
     */
    public liveBlocksAuth({
requestBody,
}: {
requestBody: LiveBlocksAuthDto,
}): CancelablePromise<LiveBlocksResponseDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/auth/liveblocks-auth',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

}
