/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessProfileResponse } from '../models/AccessProfileResponse';
import type { CreateAccessProfileDto } from '../models/CreateAccessProfileDto';
import type { CreateUserAccessProfileRelationDto } from '../models/CreateUserAccessProfileRelationDto';
import type { DetailedAccessProfileResponse } from '../models/DetailedAccessProfileResponse';
import type { DetailedAccessProfileResponseWithUsers } from '../models/DetailedAccessProfileResponseWithUsers';
import type { UpdateAccessProfileDto } from '../models/UpdateAccessProfileDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AccessProfilesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a new access profile
     * @returns DetailedAccessProfileResponse Access Profile Created with Success
     * @throws ApiError
     */
    public createAccessProfile({
        requestBody,
        organizationId,
        featuresIds,
    }: {
        requestBody: CreateAccessProfileDto,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
        /**
         * Leave empty, the code automatically gets the features-ids from token
         */
        featuresIds?: Array<string>,
    }): CancelablePromise<DetailedAccessProfileResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/access-profiles',
            headers: {
                'organization-id': organizationId,
                'features-ids': featuresIds,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * List Features for Access Profiles
     * @returns any
     * @throws ApiError
     */
    public findAllAccessProfiles({
        page,
        itemsPerPage = 10,
        orderDirection = 'DESC',
        name,
        admin,
        organizationId,
        orderBy = 'id',
        featuresIds,
    }: {
        /**
         * The number of desired page. Starts with 1.
         */
        page: number,
        /**
         * The maximum number of rows to contain in current page.
         */
        itemsPerPage?: number,
        /**
         * Ordering direction
         */
        orderDirection?: 'ASC' | 'DESC',
        /**
         * Sort by accessProfile name
         */
        name?: string,
        /**
         * Search by features only available for admin
         */
        admin?: boolean,
        /**
         * Search by organization id only available for admin
         */
        organizationId?: number,
        /**
         * Property to order by
         */
        orderBy?: 'id' | 'name' | 'admin',

        /**
         * Leave empty, the code automatically gets the features-ids from token
         */
        featuresIds?: Array<string>,
    }): CancelablePromise<{
        data: Array<AccessProfileResponse>;
        page: number;
        total: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/access-profiles',
            headers: {
                'organization-id': organizationId,
                'features-ids': featuresIds,
            },
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'orderDirection': orderDirection,
                'name': name,
                'admin': admin,
                'organizationId': organizationId,
                'orderBy': orderBy,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Find a access profile by id
     * @returns DetailedAccessProfileResponse Access Profile deleted with Success.
     * @throws ApiError
     */
    public getAccessProfileById({
        id,
        organizationId,
        featuresIds,
    }: {
        id: string,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
        /**
         * Leave empty, the code automatically gets the permissions-ids from token
         */
        featuresIds?: Array<string>,
    }): CancelablePromise<DetailedAccessProfileResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/access-profiles/{id}',
            path: {
                'id': id,
            },
            headers: {
                'organization-id': organizationId,
                'features-ids': featuresIds,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Update a access profile
     * @returns DetailedAccessProfileResponse
     * @throws ApiError
     */
    public updateAccessProfile({
        id,
        requestBody,
        organizationId,
        featuresIds,
    }: {
        /**
         * Access Profile Id
         */
        id: number,
        requestBody: UpdateAccessProfileDto,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
        /**
         * Leave empty, the code automatically gets the features-ids from token
         */
        featuresIds?: Array<string>,
    }): CancelablePromise<DetailedAccessProfileResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/access-profiles/{id}',
            path: {
                'id': id,
            },
            headers: {
                'organization-id': organizationId,
                'features-ids': featuresIds,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Delete the given Access Profile and its values
     * @returns void
     * @throws ApiError
     */
    public removeAccessProfile({
        id,
        organizationId,
        featuresIds,
    }: {
        id: string,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
        /**
         * Leave empty, the code automatically gets the features-ids from token
         */
        featuresIds?: Array<string>,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/access-profiles/{id}',
            path: {
                'id': id,
            },
            headers: {
                'organization-id': organizationId,
                'features-ids': featuresIds,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Link users to an access profile
     * @returns DetailedAccessProfileResponseWithUsers
     * @throws ApiError
     */
    public createUserAccessProfileRelation({
        id,
        requestBody,
        featuresIds,
        organizationId,
    }: {
        id: number,
        requestBody: CreateUserAccessProfileRelationDto,
        /**
         * Leave empty, the code automatically gets the features-ids from token
         */
        featuresIds?: Array<string>,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<DetailedAccessProfileResponseWithUsers> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/access-profiles/{id}/associate-users',
            path: {
                'id': id,
            },
            headers: {
                'features-ids': featuresIds,
                'organization-id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

}
