/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserDto } from '../models/CreateUserDto';
import type { DetailedUserResponse } from '../models/DetailedUserResponse';
import type { UpdateUserDto } from '../models/UpdateUserDto';
import type { UserResponse } from '../models/UserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a new user
     * @returns DetailedUserResponse User successfully created
     * @throws ApiError
     */
    public createUser({
        requestBody,
        featuresIds,
        organizationId,
    }: {
        requestBody: CreateUserDto,
        /**
         * Leave empty, the code automatically gets the features-ids from token
         */
        featuresIds?: Array<string>,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<DetailedUserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/users',
            headers: {
                'features-ids': featuresIds,
                'organization-id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                422: `Erro ao cadastrar a entidade no banco de dados.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Get all users or filter by query params
     * @returns any
     * @throws ApiError
     */
    public findUsers({
        page,
        itemsPerPage = 10,
        orderDirection = 'DESC',
        name,
        email,
        status,
        organizationId,
        accessProfilesIds,
        orderBy,
        featuresIds,
    }: {
        /**
         * The number of desired page. Starts with 1.
         */
        page: number,
        /**
         * The maximum number of rows to contain in current page.
         */
        itemsPerPage?: number,
        /**
         * Ordering direction
         */
        orderDirection?: 'ASC' | 'DESC',
        /**
         * Search by name
         */
        name?: string,
        /**
         * Search by email
         */
        email?: string,
        /**
         * Search by status
         */
        status?: 'PENDING' | 'ACTIVE' | 'INACTIVE',

        /**
         * Search by accessProfile ids
         */
        accessProfilesIds?: Array<string>,
        /**
         * Field to order
         */
        orderBy?: 'name' | 'status' | 'email' | 'organizationId' | 'accessProfilesIds',
        /**
         * Leave empty, the code automatically gets the features-ids from token
         */
        featuresIds?: Array<string>,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: number,
    }): CancelablePromise<{
        data: Array<UserResponse>;
        page: number;
        total: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/users',
            headers: {
                'features-ids': featuresIds,
                'organization-id': organizationId,
            },
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'orderDirection': orderDirection,
                'name': name,
                'email': email,
                'status': status,
                'organizationId': organizationId,
                'accessProfilesIds': accessProfilesIds,
                'orderBy': orderBy,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Inactivate user
     * set user status to "INACTIVE"
     * @returns UserResponse User successfully inactivated
     * @throws ApiError
     */
    public inactivateUser({
        id,
        userId,
        organizationId,
        featuresIds,
    }: {
        id: number,
        /**
         * Leave empty, the code automatically gets the user-id from token
         */
        userId?: string,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
        /**
         * Leave empty, the code automatically gets the features-ids from token
         */
        featuresIds?: Array<string>,
    }): CancelablePromise<UserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/users/{id}/inactivate',
            path: {
                'id': id,
            },
            headers: {
                'user-id': userId,
                'organization-id': organizationId,
                'features-ids': featuresIds,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Activate user
     * set user status to "ACTIVE"
     * @returns UserResponse User successfully activated
     * @throws ApiError
     */
    public activateUser({
        id,
        organizationId,
        featuresIds,
    }: {
        id: number,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
        /**
         * Leave empty, the code automatically gets the features-ids from token
         */
        featuresIds?: Array<string>,
    }): CancelablePromise<UserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/users/{id}/activate',
            path: {
                'id': id,
            },
            headers: {
                'organization-id': organizationId,
                'features-ids': featuresIds,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Find an user by id
     * @returns DetailedUserResponse Return a user
     * @throws ApiError
     */
    public findUserById({
        id,
        organizationId,
        featuresIds,
    }: {
        id: number,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
        /**
         * Leave empty, the code automatically gets the permissions-ids from token
         */
        featuresIds?: Array<string>,
    }): CancelablePromise<DetailedUserResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/users/{id}',
            path: {
                'id': id,
            },
            headers: {
                'organization-id': organizationId,
                'features-ids': featuresIds,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Update an user
     * @returns DetailedUserResponse
     * @throws ApiError
     */
    public updateUser({
        id,
        requestBody,
        organizationId,
        featuresIds,
    }: {
        id: number,
        requestBody: UpdateUserDto,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
        /**
         * Leave empty, the code automatically gets the features-ids from token
         */
        featuresIds?: Array<string>,
    }): CancelablePromise<DetailedUserResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/users/{id}',
            path: {
                'id': id,
            },
            headers: {
                'organization-id': organizationId,
                'features-ids': featuresIds,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `User not found`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Delete an user
     * @returns any
     * @throws ApiError
     */
    public deleteUser({
        id,
        organizationId,
        featuresIds,
    }: {
        id: string,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
        /**
         * Leave empty, the code automatically gets the features-ids from token
         */
        featuresIds?: Array<string>,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/users/{id}',
            path: {
                'id': id,
            },
            headers: {
                'organization-id': organizationId,
                'features-ids': featuresIds,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

}
