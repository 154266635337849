import { ButtonVariantsMap } from "./types";

export const BUTTON_VARIANTS_MAP: ButtonVariantsMap = {
  primaryFilled: {
    muiVariant: "contained",
    color: "primary",
  },
  primaryOutlined: {
    muiVariant: "outlined",
    color: "primary",
  },
  dangerFilled: {
    muiVariant: "contained",
    color: "error",
  },
  dangerOutlined: {
    muiVariant: "outlined",
    color: "error",
  },
  text: {
    muiVariant: "text",
    color: "primary",
  },
};

const BASE_COLORS = {
  silver: "#F4F4F4",
  white: "#FFFFFF",
  almostWhite: "#FCFCFC",
  neutral4: "#6f767E",
  neutral5: "#33383F",
  border: "#EFEFEF",
  lightBlue: "#B1E5FC",
  lightGreen: "#B5E4CA",
  yellow: "#FFD88D",
  lightPurple: "#CABDFF",
  purple: "#8E59FF",
  darkPurple: "#472D80",
  almostBlack: "#1A1D1F",
  darkBackground: "#1E2630",
  lightGray: "#9A9FA5",
  black: "#000000",
  lightWhite: "#FCFCFC",
  onlineGreen: "#83BF6E",
  pink: "#E563B6",
  blue: "#75CFFB",
  error: "#FF6A55",
};

export const COLORS = {
  ...BASE_COLORS,
  gradient: `linear-gradient(135.56deg, ${BASE_COLORS.pink} -6.65%, ${BASE_COLORS.purple} 43.08%, ${BASE_COLORS.blue} 92.81%);`,
};
