/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressTypeResponse } from '../models/AddressTypeResponse';
import type { BodyAddressTypeDto } from '../models/BodyAddressTypeDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AddressTypeService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Address Types
     * @returns any
     * @throws ApiError
     */
    public getAddressType({
        page,
        itemsPerPage = 10,
        orderDirection = 'DESC',
        name,
        organizationId,
    }: {
        /**
         * The number of desired page. Starts with 1.
         */
        page: number,
        /**
         * The maximum number of rows to contain in current page.
         */
        itemsPerPage?: number,
        /**
         * Ordering direction
         */
        orderDirection?: 'ASC' | 'DESC',
        name?: string,
        /**
         * Organization Id
         */
        organizationId?: string,
    }): CancelablePromise<{
        data: Array<AddressTypeResponse>;
        page: number;
        total: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/address-types',
            headers: {
                'organization-id': organizationId,
            },
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'orderDirection': orderDirection,
                'name': name,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Create a new address type
     * @returns AddressTypeResponse Address Type Created with Success
     * @throws ApiError
     */
    public createAddressType({
        requestBody,
        organizationId,
    }: {
        requestBody: BodyAddressTypeDto,
        /**
         * Organization Id
         */
        organizationId?: string,
    }): CancelablePromise<AddressTypeResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/address-types',
            headers: {
                'organization-id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

}
