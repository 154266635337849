/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyAddressResponseDto } from '../models/CompanyAddressResponseDto';
import type { CompanyAddressUpdateDTO } from '../models/CompanyAddressUpdateDTO';
import type { CompanyContactResponseDto } from '../models/CompanyContactResponseDto';
import type { CompanyContactUpdateDTO } from '../models/CompanyContactUpdateDTO';
import type { CompanyResponseDTO } from '../models/CompanyResponseDTO';
import type { CompanyResponseWithValuesDTO } from '../models/CompanyResponseWithValuesDTO';
import type { CreateCompanyDTO } from '../models/CreateCompanyDTO';
import type { GetCompanyByIdResponseDto } from '../models/GetCompanyByIdResponseDto';
import type { UpdateCompanyDTO } from '../models/UpdateCompanyDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CompaniesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a new company
     * @returns CompanyResponseWithValuesDTO Company Created with Success
     * @throws ApiError
     */
    public createCompany({
        requestBody,
        organizationId,
    }: {
        requestBody: CreateCompanyDTO,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<CompanyResponseWithValuesDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/companies',
            headers: {
                'organization-id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * List Companies
     * @returns any
     * @throws ApiError
     */
    public getCompanies({
        page,
        itemsPerPage = 10,
        orderDirection = 'DESC',
        tradeName,
        businessName,
        cnpj,
        orderBy = 'tradeName',
        organizationId,
    }: {
        /**
         * The number of desired page. Starts with 1.
         */
        page: number,
        /**
         * The maximum number of rows to contain in current page.
         */
        itemsPerPage?: number,
        /**
         * Ordering direction
         */
        orderDirection?: 'ASC' | 'DESC',
        /**
         * Retrieve compananies filtered by their Trade Name.
         */
        tradeName?: string,
        /**
         * Retrieve compananies filtered by their Business Name.
         */
        businessName?: string,
        /**
         * Retrieve compananies filtered by their CNPJ.
         */
        cnpj?: string,
        /**
         * Retrieve rows ordered by the choosen field.
         */
        orderBy?: 'tradeName' | 'businessName' | 'cnpj',
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<{
        data: Array<CompanyResponseDTO>;
        page: number;
        total: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/companies',
            headers: {
                'organization-id': organizationId,
            },
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'orderDirection': orderDirection,
                'tradeName': tradeName,
                'businessName': businessName,
                'cnpj': cnpj,
                'orderBy': orderBy,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Get Company by id
     * @returns GetCompanyByIdResponseDto Company find with success
     * @throws ApiError
     */
    public getCompanyById({
        companyId,
        organizationId,
    }: {
        /**
         * The id of the organization to be actioned on
         */
        companyId: number,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<GetCompanyByIdResponseDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/companies/{companyId}',
            path: {
                'companyId': companyId,
            },
            headers: {
                'organization-id': organizationId,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Delete the given Company and its values
     * @returns void
     * @throws ApiError
     */
    public deleteCompany({
        companyId,
        organizationId,
    }: {
        companyId: string,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v2/companies/{companyId}',
            path: {
                'companyId': companyId,
            },
            headers: {
                'organization-id': organizationId,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Update the given Company
     * @returns CompanyResponseWithValuesDTO Company updated with Success.
     * @throws ApiError
     */
    public updateCompany({
        companyId,
        requestBody,
        organizationId,
    }: {
        /**
         * The id of the organization to be actioned on
         */
        companyId: number,
        /**
         * The information to be updated on the given Company.
         */
        requestBody: UpdateCompanyDTO,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<CompanyResponseWithValuesDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/companies/{companyId}',
            path: {
                'companyId': companyId,
            },
            headers: {
                'organization-id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Update the given Company
     * @returns CompanyAddressResponseDto Company addresess updated with Success.
     * @throws ApiError
     */
    public updateCompanyAddresses({
        companyId,
        requestBody,
        organizationId,
    }: {
        /**
         * The id of the organization to be actioned on
         */
        companyId: number,
        /**
         * The addresses to be updated on the given Company.
         */
        requestBody: Array<CompanyAddressUpdateDTO>,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<Array<CompanyAddressResponseDto>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/companies/{companyId}/addresses',
            path: {
                'companyId': companyId,
            },
            headers: {
                'organization-id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Update the given Company
     * @returns CompanyContactResponseDto Company contacts updated with Success.
     * @throws ApiError
     */
    public updateCompanyContacts({
        companyId,
        requestBody,
        organizationId,
    }: {
        /**
         * The id of the organization to be actioned on
         */
        companyId: number,
        /**
         * The addresses to be updated on the given Company.
         */
        requestBody: Array<CompanyContactUpdateDTO>,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<Array<CompanyContactResponseDto>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v2/companies/{companyId}/contacts',
            path: {
                'companyId': companyId,
            },
            headers: {
                'organization-id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

}
