import { swaggerClient } from "./api";
import { AuthService as AuthApi } from "./__generated__";
import * as msal from "@azure/msal-browser";

interface Response {
  token: string;
  user: {
    id: number;
    name: string;
    email: string;
    organizationName: string;
  };
}

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID ?? "",
  },
};
class AuthService {
  constructor() {
    this.authApi = swaggerClient.auth;
  }

  private msalInstance: msal.PublicClientApplication =
    new msal.PublicClientApplication(msalConfig);
  private authApi: AuthApi;

  async forgotPassword(email: string) {
    try {
      await this.authApi.forgotPassword({ requestBody: { email } });
    } catch (error) {}
  }

  async signIn(email: string, password: string): Promise<Response> {
    const authObj = await this.authApi.login({
      requestBody: {
        email,
        password,
      },
    });

    const userProfile = await this.authApi.getProfile();

    return {
      token: authObj.access_token,
      user: {
        id: userProfile.id,
        name: userProfile.name,
        email,
        organizationName: userProfile.organization.name,
      },
    };
  }

  async logout() {
    return this.authApi.logout();
  }

  async SSOSignIn() {
    const msalToken = await this.msalInstance.acquireTokenPopup({
      scopes: ["User.Read"],
      prompt: "select_account",
    });
    const authObj = await this.authApi.loginAzure({
      requestBody: { accessToken: msalToken.accessToken },
    });

    const userProfile = await this.authApi.getProfile();

    return {
      token: authObj.access_token,
      user: {
        id: userProfile.id,
        name: userProfile.name,
        email: userProfile.email,
        organizationName: userProfile.organization.name,
      },
    };
  }

  async SSOSignInGoogle(token: string) {
    const authObj = await this.authApi.loginGoogle({
      requestBody: { accessToken: token },
    });

    const userProfile = await this.authApi.getProfile();

    return {
      token: authObj.access_token,
      user: {
        id: userProfile.id,
        name: userProfile.name,
        email: userProfile.email,
        organizationName: userProfile.organization.name,
      },
    };
  }

  async signOut() {
    return;
  }

  async changePassword(password: string, token: string): Promise<void> {
    await this.authApi.changePassword({
      requestBody: {
        newPassword: password,
        token: token,
      },
    });
  }
}

export const authService = new AuthService();
