import { SidebarDefaultProps } from "shared/components/SidebarList/types";
import { PRIVATE_ROUTES } from "utils/constants";

const backofficeRoutes = PRIVATE_ROUTES.backoffice;
export const BACKOFFICE_COMPONENTS: Array<SidebarDefaultProps> = [
  {
    order: 1,
    label: "Empresas",
    route: backofficeRoutes.companies,
  },
  {
    order: 2,
    label: "Organizações",
    route: backofficeRoutes.organizations,
  },
  {
    order: 3,
    label: "Inclusão de campos",
    route: backofficeRoutes.dynamicFields,
  },
  {
    order: 4,
    label: "Campos e entidades",
    route: backofficeRoutes.fieldsAndEntities,
    divider: true,
  },
  {
    order: 5,
    label: "Usuários",
    route: backofficeRoutes.users.table,
  },
  {
    order: 6,
    label: "Perfil de Acesso",
    route: backofficeRoutes.accessProfiles,
  },
];
