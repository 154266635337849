import { AxiosInstance } from "axios";

export function generateApiAuthInterceptor(axios: AxiosInstance) {
  axios.interceptors.request.use(
    (config) => {
      //TODO
      if (config.url?.includes(config.baseURL!)) {
        config.headers!["ngrok-skip-browser-warning"] = "any";
        config.headers!["Organization-Id"] = "1";
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // if (error?.response?.status === 401) {
      //   localStorage.clear();
      //   window.location.reload();
      // }
      if (
        error?.response?.status === 401 &&
        /inativad/i.test(error?.response?.data?.message)
      ) {
        localStorage.clear();
        window.location.reload();
      }

      throw error;
    }
  );
}
