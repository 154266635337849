import { ADMIN_PERMISSIONS } from "contexts/Permissions";
import { PUBLIC_ROUTES } from "utils/constants";
import jwt_decode from "jwt-decode";
import { TokenJWT } from "./types";

export const parseJwt = (token: string): TokenJWT | null => {
  try {
    return jwt_decode(token) as TokenJWT;
  } catch (e) {
    return null;
  }
};

export const isRoutePublicOrGlobal = (locationPathname: string) => {
  return Object.values({ ...PUBLIC_ROUTES }).includes(locationPathname);
};

export const checkIsAdmin = (permissions: number[]) => {
  return (
    ADMIN_PERMISSIONS.filter((feature) => permissions?.includes(feature))
      .length > 0
  );
};
