import { checkIsAdmin, parseJwt } from "contexts/Auth/helpers";
import { createContext, FC, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import { PUBLIC_ROUTES } from "utils/constants";
import { AuthContextData, User } from "./types";
import { authService } from "services/authService";
import useSnackbar from "shared/components/Snackbar/hooks/useSnackbar";
import { useGoogleLogin } from "@react-oauth/google";

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [signingIn, setSigningIn] = useState(false);
  const snackbar = useSnackbar();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === PUBLIC_ROUTES.CHANGE_PASSWORD) {
      signOut();
    }
    const getCurrentSession = async () => {
      try {
        const { data } = await axios.get("/v1/auth/profile");
        if (data) {
          setUser({
            ...data,
            featuresIds: data.featureIds,
            organizationId: data.organization.id,
            isAdmin: checkIsAdmin(data.featureIds),
            avatar: data.avatar,
          });
        }
      } finally {
        setLoading(false);
      }
    };

    getCurrentSession();
    // eslint-disable-next-line
  }, []);

  const ssoSignIn = async () => {
    setSigningIn(true);
    try {
      const response = await authService.SSOSignIn();

      const decodedJwt = parseJwt(response.token);

      if (!decodedJwt) {
        throw Error("Token inválido!");
      }
      setUser({
        ...response.user,
        featuresIds: decodedJwt.featureIds,
        isAdmin: checkIsAdmin(decodedJwt.featureIds),
        organizationId: decodedJwt.organizationId,
        avatar: decodedJwt.avatar,
      });

      snackbar.showSuccess("", "Login efetuado com sucesso!");
    } catch (error: any) {
      console.error(error);
      if (error.name !== "BrowserAuthError") {
        snackbar.showError("Login falhou", error?.body?.message, {
          position: {
            horizontal: "left",
            vertical: "bottom",
          },
        });
      }
    }
    setSigningIn(false);
  };

  const ssoGoogleSignIn = useGoogleLogin({
    onNonOAuthError: () => {
      setSigningIn(false);
    },
    onSuccess: async (data) => {
      try {
        const response = await authService.SSOSignInGoogle(data.access_token);
        const decodedJwt = parseJwt(response.token);

        if (!decodedJwt) {
          throw Error("Token inválido!");
        }

        setUser({
          ...response.user,
          featuresIds: decodedJwt.featureIds,
          isAdmin: checkIsAdmin(decodedJwt.featureIds),
          organizationId: decodedJwt.organizationId,
          avatar: decodedJwt.avatar,
        });

        snackbar.showSuccess("", "Login efetuado com sucesso!");
      } catch (error: any) {
        console.error(error);
        if (error.name !== "BrowserAuthError") {
          snackbar.showError("Login falhou", error?.body?.message, {
            position: {
              horizontal: "left",
              vertical: "bottom",
            },
          });
        }
      }
      setSigningIn(false);
    },
  });

  const signIn = async (email: string, password: string) => {
    setSigningIn(true);
    const response = await authService.signIn(email, password);
    setSigningIn(false);
    const decodedJwt = parseJwt(response.token);

    if (!decodedJwt) {
      throw Error("Token inválido!");
    }
    setUser({
      ...response.user,
      featuresIds: decodedJwt.featureIds,
      isAdmin: checkIsAdmin(decodedJwt.featureIds),
      organizationId: decodedJwt.organizationId,
      avatar: decodedJwt.avatar,
    });
  };

  const signOut = async () => {
    localStorage.clear();
    const newCookieValue =
      "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    document.cookie = newCookieValue;

    authService.logout();

    setUser(null);
  };
  return (
    <AuthContext.Provider
      value={{
        signedIn: Boolean(user),
        user,
        setUser,
        signIn,
        signOut,
        loading,
        ssoSignIn,
        signingIn,
        setSigningIn,
        ssoGoogleSignIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
export default AuthContext;
