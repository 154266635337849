import { StyledAlert } from "./style";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AlertProps } from "./types";
import { Typography, AlertTitle } from "@mui/material";
import { CheckSuccessIcon } from "../../../assets/icons/CheckSuccessIcon";
import { CheckErrorIcon } from "../../../assets/icons/CheckErrorIcon";

const icons = {
  success: <CheckSuccessIcon />,
  error: <CheckErrorIcon />,
  warning: <ErrorOutlineOutlinedIcon />,
  info: <InfoOutlinedIcon />,
};

export const Alert: React.VoidFunctionComponent<AlertProps> = ({
  type,
  title,
  message,
  mini,
}) => {
  return (
    <StyledAlert
      type={type}
      mini={mini}
      iconMapping={icons}
      severity={type}
      title={title}
      data-testid={"alert-component"}
    >
      {title ? <AlertTitle>{title}</AlertTitle> : null}
      {Array.isArray(message) ? (
        message.map((item) => <Typography variant="body1"> {item}</Typography>)
      ) : (
        <Typography variant="body1"> {message}</Typography>
      )}
    </StyledAlert>
  );
};
