/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDynamicFieldDto } from '../models/CreateDynamicFieldDto';
import type { CreateDynamicFieldRelationsDto } from '../models/CreateDynamicFieldRelationsDto';
import type { CustomEntityDto } from '../models/CustomEntityDto';
import type { DynamicFieldRelationsDto } from '../models/DynamicFieldRelationsDto';
import type { DynamicFieldRelationsResponseDto } from '../models/DynamicFieldRelationsResponseDto';
import type { DynamicFieldWithOptionsDto } from '../models/DynamicFieldWithOptionsDto';
import type { DynamicFieldWithoutOptionsDto } from '../models/DynamicFieldWithoutOptionsDto';
import type { UpdateDynamicFieldDto } from '../models/UpdateDynamicFieldDto';

import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';

export class DynamicFieldsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a new Dynamic Field
     * @returns DynamicFieldWithOptionsDto The Dynamic Field has been successfully created.
     * @throws ApiError
     */
    public createDynamicFields({
        requestBody,
        organizationId,
    }: {
        requestBody: CreateDynamicFieldDto,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<DynamicFieldWithOptionsDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dynamic-fields',
            headers: {
                'organization-id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * List all Dynamic Fields of an organization
     * @returns any
     * @throws ApiError
     */
    public findAllDynamicFields({
        page,
        organizationId,
        itemsPerPage = 10,
        orderDirection = 'DESC',
        label,
        name,
        type,
        orderBy = 'label',
    }: {
        /**
         * The number of desired page. Starts with 1.
         */
        page: number,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
        /**
         * The maximum number of rows to contain in current page.
         */
        itemsPerPage?: number,
        /**
         * Ordering direction
         */
        orderDirection?: 'ASC' | 'DESC',
        /**
         * Retrieve fields filtered by their label.
         */
        label?: string,
        /**
         * Retrieve fields filtered by their name.
         */
        name?: string,
        /**
         * Retrieve fields filtered by their type.
         */
        type?: 'Text' | 'Numeric' | 'List',
        orderBy?: 'name' | 'label' | 'type',
    }): CancelablePromise<{
        data: Array<DynamicFieldWithoutOptionsDto>;
        page: number;
        total: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/dynamic-fields',
            headers: {
                'organization-id': organizationId,
            },
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'orderDirection': orderDirection,
                'label': label,
                'name': name,
                'type': type,
                'orderBy': orderBy,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Get a Dynamic Field by id
     * @returns DynamicFieldWithOptionsDto The Dynamic Field has been successfully found.
     * @throws ApiError
     */
    public getDynamicFieldById({
        fieldId,
        organizationId,
    }: {
        fieldId: number,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<DynamicFieldWithOptionsDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/dynamic-fields/{fieldId}',
            path: {
                'fieldId': fieldId,
            },
            headers: {
                'organization-id': organizationId,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Update a Dynamic Field by id
     * If the field is not provided, it will be deleted. If the field provided does not exist, it will be created
     * @returns DynamicFieldWithOptionsDto The Dynamic Field has been successfully updated.
     * @throws ApiError
     */
    public updateDynamicField({
        fieldId,
        requestBody,
        organizationId,
    }: {
        fieldId: number,
        requestBody: UpdateDynamicFieldDto,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<DynamicFieldWithOptionsDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/dynamic-fields/{fieldId}',
            path: {
                'fieldId': fieldId,
            },
            headers: {
                'organization-id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Delete a Dynamic Field by id
     * The options associated with the field will be deleted as well
     * @returns void
     * @throws ApiError
     */
    public removeDynamicField({
        fieldId,
        organizationId,
    }: {
        fieldId: number,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/dynamic-fields/{fieldId}',
            path: {
                'fieldId': fieldId,
            },
            headers: {
                'organization-id': organizationId,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Get all the dynamic fields related to the given entity in a organization
     * @returns DynamicFieldWithOptionsDto The Dynamic Field has been successfully found.
     * @throws ApiError
     */
    public findByEntityId({
        entityId,
        organizationId,
    }: {
        entityId: number,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<Array<DynamicFieldWithOptionsDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/dynamic-fields/entity/{entityId}',
            path: {
                'entityId': entityId,
            },
            headers: {
                'organization-id': organizationId,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * List Entities for DynamicField relations
     * @returns any
     * @throws ApiError
     */
    public listEntities({
        page,
        itemsPerPage = 10,
        orderDirection = 'DESC',
        label,
        orderBy = 'label',
    }: {
        /**
         * The number of desired page. Starts with 1.
         */
        page: number,
        /**
         * The maximum number of rows to contain in current page.
         */
        itemsPerPage?: number,
        /**
         * Ordering direction
         */
        orderDirection?: 'ASC' | 'DESC',
        /**
         * Retrieve fields filtered by their label.
         */
        label?: string,
        orderBy?: 'label',
    }): CancelablePromise<{
        data: Array<CustomEntityDto>;
        page: number;
        total: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/entities',
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'orderDirection': orderDirection,
                'label': label,
                'orderBy': orderBy,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * List Dynamic Fields Relations with Entities
     * @returns any
     * @throws ApiError
     */
    public getDynamicFieldsRelations({
        page,
        organizationId,
        itemsPerPage = 10,
        orderDirection = 'DESC',
        fieldId,
        entityId,
        orderBy = 'fieldId',
    }: {
        /**
         * The number of desired page. Starts with 1.
         */
        page: number,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
        /**
         * The maximum number of rows to contain in current page.
         */
        itemsPerPage?: number,
        /**
         * Ordering direction
         */
        orderDirection?: 'ASC' | 'DESC',
        /**
         * Retrieve relations filtered by their Dynamic Field.
         */
        fieldId?: number,
        /**
         * Retrieve relations filtered by their Entity.
         */
        entityId?: number,
        orderBy?: 'id' | 'fieldId' | 'dynamicFieldName' | 'entityId' | 'entityLabel',
    }): CancelablePromise<{
        data: Array<DynamicFieldRelationsDto>;
        page: number;
        total: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/dynamic-field-relations',
            headers: {
                'organization-id': organizationId,
            },
            query: {
                'page': page,
                'items_per_page': itemsPerPage,
                'orderDirection': orderDirection,
                'fieldId': fieldId,
                'entityId': entityId,
                'orderBy': orderBy,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Create a new Dynamic Field Relation
     * @returns DynamicFieldRelationsResponseDto The Dynamic Field has been successfully created.
     * @throws ApiError
     */
    public createDynamicFieldRelations({
        requestBody,
        organizationId,
    }: {
        requestBody: CreateDynamicFieldRelationsDto,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<DynamicFieldRelationsResponseDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/dynamic-field-relations',
            headers: {
                'organization-id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    //update
    /**
     * Update a Dynamic Field Relation by id
     * @returns DynamicFieldRelationsResponseDto The Dynamic Field has been successfully updated.
     * @throws ApiError
     */
    public updateDynamicFieldRelations({
        relationId,
        requestBody,
        organizationId,
    }: {
        relationId: number,
        requestBody: Partial<CreateDynamicFieldRelationsDto>,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<DynamicFieldRelationsResponseDto> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/dynamic-field-relations/{relationId}',
            path: {
                'relationId': relationId,
            },
            headers: {
                'organization-id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                422: `Due to system constraints was not possible to created the given entity. Probably other exists with the same name.`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

    /**
     * Delete a Dynamic Field Relation by id
     * @returns void
     * @throws ApiError
     */
    public deleteDynamicField({
        relationId,
        organizationId,
    }: {
        relationId: number,
        /**
         * Leave empty, the code automatically gets the organization-id from token
         */
        organizationId?: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/dynamic-field-relations/{relationId}',
            path: {
                'relationId': relationId,
            },
            headers: {
                'organization-id': organizationId,
            },
            errors: {
                400: `Bad Request: Invalid Sintax`,
                401: `Not Authorized: Missing or Expired Authentication parameters`,
                403: `Forbidden: Mostly due to current user hasn't the needed AccessProfile to perform this operation.`,
                404: `Not Found: The organization Id provided was not found in our database`,
                500: `Internal Server Error: Some unexpected error related to internal infra-structure.`,
            },
        });
    }

}
