import { createContext, useCallback, useMemo } from "react";
import { useAuth } from "../Auth";
import {
  Actions,
  FeaturesPermissions,
  PERMISSION_TO_ROUTE_MAP,
  PermissionModules,
} from "./constants";
import { Context } from "./types";

export const PermissionsContext = createContext<Context>({
  checkPermission: () => false,
  checkRoutePermission: () => false,
});

export const PermissionsProvider: React.FC = ({ children }) => {
  const { user } = useAuth();

  const permissions = useMemo(() => user?.featuresIds || [], [user]);
  const checkPermission = useCallback(
    (
      neededModules: PermissionModules | PermissionModules[],
      actions?: Actions[]
    ) => {
      if (neededModules === PermissionModules.Basic) {
        return permissions.includes(FeaturesPermissions[neededModules]);
      }
      if (!actions) return false;

      const isAllowedTo = (action: Actions) => {
        if (Array.isArray(neededModules)) {
          return neededModules.some((mod) => {
            if (mod === PermissionModules.Basic) {
              return permissions.includes(FeaturesPermissions[mod]);
            }

            return permissions.includes(FeaturesPermissions[mod][action]);
          });
        }
        return permissions.includes(FeaturesPermissions[neededModules][action]);
      };
      const hasSomeRequiredPermissions = actions.some((action) =>
        isAllowedTo(action)
      );
      return hasSomeRequiredPermissions;
    },
    [permissions]
  );

  const checkRoutePermission = useCallback(
    (routeToCheck: string) => {
      const moduleAndPermission = PERMISSION_TO_ROUTE_MAP[routeToCheck];
      if (moduleAndPermission) {
        return moduleAndPermission.modules.some((module) =>
          checkPermission(module, moduleAndPermission.actions)
        );
      }
      return true;
    },
    [checkPermission]
  );

  return (
    <PermissionsContext.Provider
      value={{ checkPermission, checkRoutePermission }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};
